<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Orientation Batch</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
          
          <div class="mb-4">  
            <!-- advance serach -->
            <div class="d-flex">
                <div class="d-flex flex-column me-2">
                      <label class="col-form-label">Orientation Batch</label>
                      <div style="width: 180px">
                          <v-select label="batch" v-model="filter_batch" :options="batch" :clearable="false"
                              :selectable="(options) => filter_batch.id != options.id " :loading="!batch.length"
                              @option:selected="FilterData(filter_batch, program_batch)">
                          </v-select>
                      </div>
                  </div>
                  <div class="d-flex flex-column me-2">
                      <label class="col-form-label">Program Batch</label>
                      <div style="width: 180px">
                          <v-select label="batch_no" v-model="program_batch" :options="program_batchs" :clearable="false"
                              :selectable="(options) => program_batch.id != options.id " :loading="!program_batchs.length"
                              @option:selected="FilterData(filter_batch, program_batch)">
                          </v-select>
                      </div>
                  </div>
                  
                  <div class="d-flex mt-4">
                      <!-- <div class="col-form-label d-none">hfhd</div> -->
                      <div @click="refreshData()" class="icon-css">
                          <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                      </div>
                  </div> 
              </div>  
                        <!--end advance serach -->

            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'orientation-batch-create' }">
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light"
                  v-if="hasPermissions(['orientation-batch-create'])"
                >
                  <i class="mdi mdi-plus-circle"></i> Add Batch
                </button>
              </router-link>
            </div>
          </div>  
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="orientation-batch-list-datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Orientation Batch</th>
                    <th>Program Batch</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in batch" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.batch }}</td>
                    <td>
                      <span
                        v-for="(data, index) in item.program_batch"
                        :key="index"
                        >{{ data.batch_no }}
                        <span v-if="index + 1 < item.program_batch.length"
                          >,
                        </span>
                      </span>
                    </td>
                    <td>
                      <span>
                        <router-link
                          class="action-icon"
                          :to="{
                            name: 'orientation-batch-update',
                            params: { id: item.id },
                          }"
                        >
                          <button
                            v-if="item.orientation_award"
                            type="button"
                            class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                          >
                            <i class="fas fa-eye"></i>
                            View
                          </button>
                          <i class="mdi mdi-square-edit-outline" v-else ></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
//Datatable Orientation Batch
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {vSelect},
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      batch: [],
      program_batchs : [],
      filter_batch : "",
      program_batch : "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async getAllOrientationBatch() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/orientation-batch`)
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#orientation-batch-list-datatable").DataTable();
    },
    async getProgramBatch() {
      this.loading = true;
      await axios
          .get(`${this.baseUrlHRIS}api/program-batches/list`)
          .then((response) => {
              this.program_batchs = response.data.data;
          })
          .catch(() => {
              this.toast.error("Not Found Batch!");
          });
    },
    FilterData(ori_batch, pro_batch) {
        this.clearFilter();
        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            const check_ori = ori_batch?.batch == data[1];
            const check_pro = data[2].includes(pro_batch?.batch_no);
            if((check_ori || !ori_batch) && (check_pro || !pro_batch)){
                return true;
            }
            return false;
        });
        $("#orientation-batch-list-datatable").DataTable().draw();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#orientation-batch-list-datatable").DataTable().draw();
    },
    refreshData() {
      this.batch = [];
      this.filter_batch = "";
      this.program_batch = "";
      this.clearFilter();
      this.getAllOrientationBatch();
    }
  },
  created() {
    this.clearFilter();
    this.getAllOrientationBatch();
    this.getProgramBatch();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
  